import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import { UserCog } from "lucide-react";
import SelectGame from "./SelectGame";
import lol from "../../assets/icons8-league-of-legends-100.png";
import valorant from "../../assets/icons8-valorant-100.png";
import fifa from "../../assets/icons8-fifa-21-100.png";
import Verify from "./Verify";
import Region from "./Region";
import Role from "./Role";
import ActionButton from "../../components/ActionButton";
import ProfileModal from "../../components/ProfileModal";
import Loader from "../../components/Loader";
import Offers from "./Offers";
import DiscordCheck from "./DiscordCheck";
import CountryFlagSelector from "../../components/CountryFlagSelector";

const Index = ({ userData }) => {
  const [game, setGame] = useState(null);
  const [region, setRegion] = useState(null);
  const [role, setRole] = useState(null);
  const [receiveOffers, setReceiveOffers] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [check, setCheck] = useState(false);
  const [isProfileConfigVisible, setIsProfileConfigVisible] = useState(true);
  const [selectedCountryCode, setSelectedCountryCode] = useState(null);

  const [userInfo, setUserInfo] = useState({
    verified: false,
  });

  const games = [
    { id: "lol", name: "LoL", logo: lol, enabled: true },
    { id: "valorant", name: "Valorant", logo: valorant, enabled: false },
    { id: "fifa", name: "FIFA", logo: fifa, enabled: false },
  ];

  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await fetch("/api/check_user_profile/");
        const data = await response.json();

        if (data.has_profile) {
          setRegion(data.user_profile.region);
          setRole(data.user_profile.role);
          setReceiveOffers(data.user_profile.receiving_offers);
          setGame("lol");
          setSelectedCountryCode(data.user_profile.country)
        }

        setUserInfo({ verified: data.has_profile });
        setUser(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Failed to fetch user profile", error);
        setIsLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  const handleSaveProfile = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post("/api/update-user-profile/", {
        role: role,
        region: region,
        receiving_offers: receiveOffers,
        country: selectedCountryCode,
      });
      setIsLoading(false);
      setIsModalVisible(true);
    } catch (error) {
      console.error("Error al enviar la solicitud:", error);
      setIsLoading(false);
    }
  };

  if (!userData || !userData.id) {
    return (
      <Container>
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      </Container>
    );
  }

  const id = userData.id;
  const avatar = userData.avatar;
  const image = avatar
    ? `https://cdn.discordapp.com/avatars/${id}/${avatar}.png`
    : "https://i.redd.it/j3t4cvgywd051.png";

  const discord_name = userData
    ? userData.discord_tag.charAt(0).toUpperCase() + userData.discord_tag.slice(1)
    : null;

  return (
    <Container>
      {isLoading ? (
        <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-50 z-50">
          <Loader />
        </div>
      ) : (
        <div className="flex flex-col gap-4 mt-4">

          {isProfileConfigVisible && (
            <div className="bg-indigo-900 p-8 rounded-lg shadow-lg">
              <h1 className="text-center font-bold text-4xl md:text-5xl text-white mb-8">
                Bienvenido a tu perfil
              </h1>
              <div className="flex flex-col items-center mb-6">
                <div className="h-32 w-32 md:h-40 md:w-40 rounded-full overflow-hidden border-4 border-yellow-500">
                  <img
                    src={image}
                    alt="Avatar de Discord"
                    className="w-full h-full object-cover"
                  />
                </div>
                <h2 className="text-2xl font-semibold text-white mt-4">
                  {userData ? discord_name : "Nombre de usuario"}
                </h2>
                <div className="mt-2">
                  <CountryFlagSelector 
                    user_country={selectedCountryCode} 
                    onCountrySelect={setSelectedCountryCode} 
                  />
                </div>
              </div>
              <p className="text-center text-white font-semibold mb-4">
                Debes verificar tu permanencia en el Discord siempre que vayas a
                realizar un cambio en tu perfil.
              </p>
              <div className="flex flex-col sm:flex-row items-center justify-center gap-4 mb-6">
                <DiscordCheck check={check} setCheck={setCheck} />
                <Verify check={check} user={userInfo} data={user} />
              </div>

              <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
                <SelectGame
                  games={games}
                  setGame={setGame}
                  selectedGame={game}
                />
                <Region setRegion={setRegion} region={region} />
                <Role selectedRole={role} setRole={setRole} />
                <Offers
                  setReceiveOffers={setReceiveOffers}
                  selectedReceiveOffers={receiveOffers}
                />
              </div>
              <div className="flex justify-center">
                <ActionButton
                  disabled={!region || !role || !game || !userInfo.verified || !check}
                  onClick={handleSaveProfile}
                  className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
                >
                  Guardar Perfil
                </ActionButton>
              </div>
              <ProfileModal
                isOpen={isModalVisible}
                onClose={() => setIsModalVisible(false)}
                game={game}
                region={region}
                role={role}
                receiving_offers={receiveOffers}
              />
            </div>
          )}

          {!isProfileConfigVisible && (
            <div className="flex justify-center mt-4">
              <ActionButton
                primary
                onClick={() => setIsProfileConfigVisible(true)}
                className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-6 rounded-full transition duration-300 ease-in-out transform hover:scale-105"
              >
                Configurar perfil
              </ActionButton>
            </div>
          )}
        </div>
      )}
    </Container>
  );
};

export default Index;