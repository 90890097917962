import React, { useState } from "react";
import { CheckCircle, XCircle, HelpCircle } from "lucide-react";

const Offers = ({ setReceiveOffers, selectedReceiveOffers }) => {
  const [isOpenToInvites, setIsOpenToInvites] = useState(selectedReceiveOffers);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const openModal = () => setIsModalVisible(true);
  const closeModal = () => setIsModalVisible(false);

  const handleInviteOptionChange = (option) => {
    setIsOpenToInvites(option);
    setReceiveOffers(option);
    closeModal();
  };

  const options = [
    {
      id: true,
      label: "Sí",
      icon: <CheckCircle size={80} className="text-green-500" />,
    },
    {
      id: false,
      label: "No",
      icon: <XCircle size={80} className="text-red-500" />,
    },
  ];

  const selectedOption = options.find(
    (option) => option.id === selectedReceiveOffers
  );

  return (
    <div className="relative">
      <div
        className="p-4 relative inset-0 flex flex-col items-center cursor-pointer"
        onClick={openModal}
      >
        <div className="bg-gray-800 bg-opacity-90 p-4 rounded-lg w-full h-40 sm:h-48 md:h-56 lg:h-64 flex flex-col items-center justify-center text-center transition-all duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg">
          {selectedOption ? (
            selectedOption.icon
          ) : (
            <HelpCircle size={60} className="text-gray-400 mb-4" />
          )}
          <span className="text-white text-lg sm:text-xl md:text-2xl lg:text-3xl font-semibold mt-4">
            Buscar Equipo
          </span>
        </div>
      </div>

      {isModalVisible && (
        <div className="fixed inset-0 z-50 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true" onClick={closeModal}></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                    <h3 className="text-2xl leading-6 font-medium text-white mb-8" id="modal-title">
                      Selecciona una opción
                    </h3>
                    <div className="grid grid-cols-2 gap-8">
                      {options.map((option) => (
                        <div
                          key={option.id}
                          className={`cursor-pointer flex flex-col items-center justify-center p-6 rounded-lg transition-all duration-300 ease-in-out transform hover:scale-105 ${
                            isOpenToInvites === option.id
                              ? "bg-blue-600"
                              : "bg-gray-700 hover:bg-gray-600"
                          }`}
                          onClick={() => handleInviteOptionChange(option.id)}
                        >
                          <div className="mb-4">{option.icon}</div>
                          <div className="text-white text-2xl font-semibold">
                            {option.label}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
              <div className="bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={closeModal}
                >
                  Cancelar
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Offers;