import React from "react";
import { LAN, LAS } from "../../components/AssetsBarrel";
import Images from "../../components/ImagesContainer";
import { CheckCircle, XCircle } from 'lucide-react';
import { useNavigate } from "react-router-dom";

const TeamCard = ({
  name,
  image,
  open,
  region,
  captain,
  captain_id,
  captain_avatar,
}) => {
  const navigate = useNavigate();

  const regionImages = {
    LAN: LAN,
    LAS: LAS,
  };

  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  return (
    <div
      className="flex flex-col gap-4 bg-gray-800 p-6 rounded-xl h-full
      transform transition-all duration-300 hover:scale-105 hover:shadow-lg hover:shadow-blue-500/20 hover:border hover:border-blue-500"
    >
      <div className="flex justify-between text-sm text-gray-300 items-center">
        <div className="flex items-center gap-2">
          <img
            src={regionImages[region]}
            alt={region}
            className="w-6 h-6"
          />
          <span>{region}</span>
        </div>
        <div className="flex items-center gap-2">
          <img
            src={`https://cdn.discordapp.com/avatars/${String(
              captain_id
            )}/${captain_avatar}.png`}
            alt="Discord Avatar"
            className="w-6 h-6 rounded-full"
            onError={(e) => {
              e.target.onerror = null;
              e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
            }}
          />
          <span>{captain}</span>
        </div>
      </div>
      <div className="flex flex-col items-center flex-grow justify-center">
        <img
          src={Images[image]}
          alt={image}
          className="w-32 h-32 object-contain mb-4 cursor-pointer transition-transform duration-300 hover:scale-110"
          onClick={() => goToTeamPage(name)}
        />
        <h3
          className="text-white text-lg font-semibold cursor-pointer
          hover:text-blue-400 transition-colors duration-300"
          onClick={() => goToTeamPage(name)}
        >
          {name}
        </h3>
      </div>
      <div className="flex justify-center items-center gap-2 text-sm">
        {open ? (
          <CheckCircle className="text-green-500" size={18} />
        ) : (
          <XCircle className="text-red-500" size={18} />
        )}
        <span className={`${open ? 'text-green-500' : 'text-red-500'}`}>
          {open ? 'Reclutando' : 'Cerrado'}
        </span>
      </div>
    </div>
  );
};

export default TeamCard;