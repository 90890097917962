import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { AiOutlineClose, AiOutlineTeam } from "react-icons/ai";
import { FaExternalLinkAlt } from "react-icons/fa";
import Loader from "../../components/Loader";

const ModalTeams = ({ tournamentId, closeModal }) => {
  const [tournamentInfo, setTournamentInfo] = useState({});
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchTournamentInfo = async () => {
    try {
      const response = await axios.get(`/api/tournament/${tournamentId}`);
      setTournamentInfo(response.data);
    } catch (error) {
      console.error("Error al obtener la información del torneo:", error);
      setTournamentInfo({});
    } finally {
      setLoading(false);
    }
  };

  const goToTeamPage = (teamName) => {
    navigate(`/league/equipos/${teamName}`);
  };

  useEffect(() => {
    fetchTournamentInfo();
  }, [tournamentId]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white w-full max-w-2xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold flex items-center">
            <AiOutlineTeam className="mr-2" />
            Lista de Equipos Registrados
          </h3>
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-white transition-colors duration-200"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        <div className="flex-grow overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-64">
              <Loader />
            </div>
          ) : (
            <table className="w-full border-collapse">
              <thead className="bg-gray-700">
                <tr>
                  <th className="p-2 text-left">Nombre del Equipo</th>
                  <th className="p-2 text-center">Detalles</th>
                </tr>
              </thead>
              <tbody>
                {tournamentInfo.teams &&
                  tournamentInfo.teams.map((teamName, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-gray-700 bg-opacity-50" : ""}
                    >
                      <td
                        className="p-2 cursor-pointer hover:text-yellow-500 transition-colors duration-200"
                        onClick={() => goToTeamPage(teamName)}
                      >
                        {teamName}
                      </td>
                      <td className="p-2 text-center">
                        <button
                          className="bg-yellow-500 text-white px-4 py-2 rounded-md hover:bg-yellow-600 transition-colors duration-200 flex items-center justify-center mx-auto"
                          onClick={() => goToTeamPage(teamName)}
                        >
                          Ver Equipo
                          <FaExternalLinkAlt className="ml-2" />
                        </button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          )}
        </div>

        <button
          onClick={closeModal}
          className="mt-4 bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-200 w-full"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalTeams;