import React, { useEffect, useState } from "react";
import axios from "axios";
import { FaBan, FaCheck, FaTimes, FaUser, FaBook, FaExclamationTriangle } from "react-icons/fa";
import Loader from "../../components/Loader";

const CancelRulesModal = ({ tournamentId, closeModal }) => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentVersion, setCurrentVersion] = useState("");
  const [bannedImages, setBannedImages] = useState({});
  const [showResponseModal, setShowResponseModal] = useState(false);
  const [responseModalContent, setResponseModalContent] = useState("");
  const [isLoadingAction, setIsLoadingAction] = useState(false);

  const fetchRules = async () => {
    try {
      setLoading(true);
      const versionResponse = await axios.get(
        "https://ddragon.leagueoflegends.com/api/versions.json"
      );
      const versions = versionResponse.data;
      const firstVersion = versions[0];
      setCurrentVersion(firstVersion);

      const rulesResponse = await axios.get(
        `api/tournament/${tournamentId}/rules`
      );
      const activeRules = rulesResponse.data.filter((rule) => rule.is_active);
      setRules(activeRules);
    } catch (error) {
      console.error("Error fetching rules:", error);
      setRules([]);
    } finally {
      setLoading(false);
    }
  };

  const handleCancelRule = async (ruleId) => {
    setIsLoadingAction(true);

    try {
      const response = await axios.post("tournament/exchange/cancel-rule/", {
        rule_id: ruleId,
      });
      setResponseModalContent(response.data.detail);
      setShowResponseModal(true);
      fetchRules(); 
    } catch (error) {
      setResponseModalContent(
        error.response?.data?.detail || "Error al cancelar regla"
      );
      setShowResponseModal(true);
    } finally {
      setIsLoadingAction(false);
    }
  };

  const fetchBannedImages = async () => {
    const bannedImagesMap = {};

    for (const rule of rules) {
      const isChampion = rule.rule_text.toLowerCase().includes("campeón");
      const isSpell = rule.rule_text.toLowerCase().includes("hechizo");
      const isItem = rule.rule_text.toLowerCase().includes("ítem");

      if (isChampion || isSpell || isItem) {
        const imageUrl = await getBannedImageUrl(
          rule,
          isChampion,
          isSpell,
          isItem
        );
        bannedImagesMap[rule.id] = imageUrl;
      }
    }

    setBannedImages(bannedImagesMap);
  };

  const getBannedImageUrl = async (rule, isChampion, isSpell, isItem) => {
    const id = rule.banned;

    if (isChampion || isSpell) {
      return await getImageUrl(isChampion, id);
    } else if (isItem) {
      return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/item/${id}.png`;
    }
    return "";
  };

  const getImageUrl = async (isChampion, id) => {
    const dataType = isChampion ? "champion" : "summoner";
    const response = await axios.get(
      `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/data/en_US/${dataType}.json`
    );
    const data = response.data.data;

    if (isChampion) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const champion = data[key];
          if (champion.id.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/${dataType}/${champion.image.full}`;
          }
        }
      }
    } else {
      for (const summonerSpell in data) {
        if (data.hasOwnProperty(summonerSpell)) {
          const spell = data[summonerSpell];
          if (spell.name.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/spell/${spell.image.full}`;
          }
        }
      }
    }

    return "";
  };

  useEffect(() => {
    fetchRules();
  }, [tournamentId]);

  useEffect(() => {
    if (currentVersion && rules.length > 0) {
      fetchBannedImages();
    }
  }, [currentVersion, rules]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <h3 className="text-2xl font-bold mb-4 flex items-center justify-center">
          <FaBan className="mr-2" />
          Lista de Reglas Activas
        </h3>

        <div className="flex-grow overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-64">
              <Loader />
            </div>
          ) : (
            <table className="w-full border-collapse">
              <thead className="bg-gray-700">
                <tr>
                  <th className="p-2 text-left"><FaUser className="inline mr-2" />Usuario</th>
                  <th className="p-2 text-left hidden lg:table-cell"><FaBook className="inline mr-2" />Regla</th>
                  <th className="p-2 text-center"><FaBan className="inline mr-2" />Banned</th>
                  <th className="p-2 text-center"><FaCheck className="inline mr-2" />Activo</th>
                  <th className="p-2 text-center"><FaExclamationTriangle className="inline mr-2" />Cancelar</th>
                </tr>
              </thead>
              <tbody>
                {rules.map((rule, index) => (
                  <tr key={rule.id} className={index % 2 === 0 ? "bg-gray-700 bg-opacity-50" : ""}>
                    <td className="p-2 flex items-center space-x-2">
                      <img
                        src={`https://cdn.discordapp.com/avatars/${rule.user_id}/${rule.user_avatar}.png`}
                        alt="Discord Avatar"
                        className="w-8 h-8 rounded-full bg-gray-600"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                        }}
                      />
                      <span>{rule.user_discord_tag}</span>
                    </td>
                    <td className="p-2 hidden lg:table-cell">{rule.rule_text}</td>
                    <td className="p-2 text-center">
                      {bannedImages[rule.id] ? (
                        <img
                          src={bannedImages[rule.id]}
                          alt="Banned"
                          className="w-8 h-8 rounded-full bg-gray-600 inline-block"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                          }}
                        />
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>
                    <td className="p-2 text-center">
                      {rule.is_active ? (
                        <FaCheck className="inline text-green-500" />
                      ) : (
                        <FaTimes className="inline text-red-500" />
                      )}
                    </td>
                    <td className="p-2 text-center">
                      {rule.is_active && (
                        <button
                          onClick={() => handleCancelRule(rule.id)}
                          className="bg-red-500 text-white px-3 py-1 rounded-md hover:bg-red-600 transition-colors duration-200"
                        >
                          Cancelar
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <button
          onClick={closeModal}
          className="mt-4 bg-yellow-500 text-gray-900 font-bold py-2 px-4 rounded-md hover:bg-yellow-600 transition-colors duration-200"
        >
          Cerrar
        </button>

        {showResponseModal && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full">
              <h3 className="font-bold text-xl mb-4">Cancelar Regla de Torneo</h3>
              <p className="mb-6">{responseModalContent}</p>
              <button
                onClick={() => setShowResponseModal(false)}
                className="w-full bg-yellow-500 hover:bg-yellow-600 text-gray-900 font-bold py-2 px-4 rounded-md transition-colors duration-200"
              >
                Cerrar
              </button>
            </div>
          </div>
        )}

        {isLoadingAction && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
            <Loader />
          </div>
        )}
      </div>
    </div>
  );
};

export default CancelRulesModal;