import React from "react";
import {
  TbProgress,
  TbProgressCheck,
  TbTrophy,
  TbTrophyOff,
} from "react-icons/tb";
import { HiDotsHorizontal } from "react-icons/hi";
import { RiTeamFill } from "react-icons/ri";
import { Coins } from "lucide-react";
import Loader from "../../components/Loader";

const BetsList = ({ bets, loading }) => {
  return (
    <div className="flex flex-col gap-4">
      {loading ? (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <Loader />
        </div>
      ) : bets.length === 0 ? (
        <div className="bg-gray-800 text-white p-6 rounded-lg shadow-lg">
          <p className="text-sm">
            No has realizado ninguna predicción aún. Realiza alguna en el servidor de Discord.
          </p>
        </div>
      ) : (
        bets.map((bet, index) => (
          <div
            key={index}
            className="bg-gray-800 text-white p-6 rounded-lg shadow-lg transform transition-all duration-300 hover:shadow-xl hover:scale-102 hover:border-yellow-500 hover:border-2"
          >
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4">
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-8 w-full sm:w-auto">
                <div className="flex items-center gap-2">
                  {bet.status === "En Curso" ? (
                    <>
                      <TbProgress className="text-yellow-500" size={24} />
                      <span className="font-semibold">En Curso</span>
                    </>
                  ) : (
                    <>
                      <TbProgressCheck className="text-green-500" size={24} />
                      <span className="font-semibold">Finalizado</span>
                    </>
                  )}
                </div>
                <div className="flex items-center gap-2">
                  {bet.result === "GANADA" ? (
                    <>
                      <TbTrophy className="text-green-500" size={24} />
                      <span className="font-semibold">Victoria</span>
                    </>
                  ) : bet.result === "PERDIDA" ? (
                    <>
                      <TbTrophyOff className="text-red-500" size={24} />
                      <span className="font-semibold">Derrota</span>
                    </>
                  ) : (
                    <>
                      <HiDotsHorizontal className="text-blue-500" size={24} />
                      <span className="font-semibold">Esperando</span>
                    </>
                  )}
                </div>
              </div>
              <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 w-full sm:w-auto">
                <div className="flex items-center gap-2 w-full sm:w-auto">
                  <RiTeamFill className="text-yellow-500 flex-shrink-0" size={24} />
                  <span className="font-semibold truncate max-w-[200px]">
                    {bet.team_bet}
                  </span>
                </div>
                <div className="flex items-center gap-2 bg-indigo-600 px-3 py-1 rounded-full">
                  <Coins size={16} className="text-yellow-300" />
                  <span className="font-semibold text-sm">{bet.coins_bet}</span>
                </div>
              </div>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default BetsList;