import React, { useEffect, useState } from "react";
import axios from "axios";
import { Users, ArrowLeftRight, RefreshCw } from "lucide-react";
import WidgetBot from "@widgetbot/react-embed";
import TitleContainer from "../../components/TitleContainer";
import Transfers from "../../components/Transfers";
import Loader from "../../components/Loader";

const Index = () => {
  const [transfers, setTransfers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransfers = async () => {
      try {
        const response = await axios.get(
          "/api/player-status-logs/?page_size=2"
        );
        setTransfers(response.data["results"]);
        setIsLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data", error);
        setIsLoading(false);
      }
    };

    fetchTransfers();
  }, []);

  return (
    <div className="min-h-screen bg-gradient-to-b from-gray-900 via-gray-800 to-gray-900 text-white">
      {" "}
      <div className="container mx-auto px-4 py-8">
        <div className="space-y-6">
          <TitleContainer
            text="Bienvenidos"
            icon={<Users className="h-6 w-6" />}
            color="bg-blue-600"
            flex="start"
          />

          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div className="space-y-6">
              <TitleContainer
                text="Últimos Movimientos"
                icon={<ArrowLeftRight className="h-6 w-6" />}
                color="bg-indigo-700"
                flex="start"
                link="/league/transferencias"
              />
              {isLoading ? (
                <div className="flex justify-center">
                  <Loader />
                </div>
              ) : (
                <div className="bg-gray-800 rounded-lg shadow-lg p-4">
                  <Transfers transfers={transfers} day />
                </div>
              )}
            </div>

            <div className="space-y-6">
              <TitleContainer
                text="Actualizaciones"
                icon={<RefreshCw className="h-6 w-6" />}
                color="bg-purple-700"
                flex="start"
              />
              <div className="bg-gray-800 rounded-lg shadow-lg overflow-hidden w-full h-[400px]">
                <WidgetBot
                  server="294295653620645888"
                  channel="385149213153034240"
                  className="w-full h-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
