import React from 'react';
import { User, Globe, Award, Coins, Users, Mail, UserPlus } from 'lucide-react';

const AgentListHeading = () => {
  return (
    <div className="hidden md:flex items-center justify-between py-2 px-4 bg-gray-700 rounded-t-lg text-white text-xs lg:text-sm font-semibold">
      <div className="w-[20%] flex items-center">
        <User size={16} className="mr-2 hidden lg:inline" />
        <span>Jugador</span>
      </div>
      <div className="w-[10%] flex items-center">
        <Globe size={16} className="mr-2 hidden lg:inline" />
        <span>Región</span>
      </div>
      <div className="w-[20%] flex items-center">
        <Award size={16} className="mr-2 hidden lg:inline" />
        <span>Rango</span>
      </div>
      <div className="w-[10%] flex items-center">
        <Coins size={16} className="mr-2 hidden lg:inline" />
        <span>Monedas</span>
      </div>
      <div className="w-[15%] flex items-center">
        <Users size={16} className="mr-2 hidden lg:inline" />
        <span>Equipo</span>
      </div>
      <div className="w-[15%] flex items-center">
        <Mail size={16} className="mr-2 hidden lg:inline" />
        <span>Ofertas</span>
      </div>
      <div className="w-[10%] flex items-center justify-end">
        <UserPlus size={16} className="mr-2 hidden lg:inline" />
        <span>Acción</span>
      </div>
    </div>
  );
};

export default AgentListHeading;