import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Search, Globe } from 'lucide-react';
import Loader from "../../components/Loader";
import TeamCard from "./TeamCard";

const TeamList = ({ userProfile2, isUserLoggedIn }) => {
  const [teams, setTeams] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [region, setRegion] = useState("ALL");
  const [filteredTeams, setFilteredTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get("/api/teams/")
      .then((response) => {
        setTeams(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error al obtener equipos:", error);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const result = teams.filter((team) => 
      (searchTerm === "" || team.name.toLowerCase().includes(searchTerm.toLowerCase())) &&
      (region === "ALL" || team.region === region)
    );
    setFilteredTeams(result);
  }, [searchTerm, region, teams]);

  const Modal = ({ type }) => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl max-w-md w-full">
        <p className="text-white text-lg mb-4">
          {type === "login" 
            ? "Necesitas estar conectado para ver rosters." 
            : "Necesitas crear un perfil primero para ver rosters."}
        </p>
        <div className="flex justify-end space-x-4">
          <button
            className="px-4 py-2 bg-gray-600 text-white rounded hover:bg-gray-700 transition-colors"
            onClick={() => setShowModal(null)}
          >
            Cerrar
          </button>
          <button
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors"
            onClick={() => navigate(type === "login" ? "/login" : "/profile")}
          >
            {type === "login" ? "Conectarse" : "Mi perfil"}
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="min-h-screen mt-6">
      <h1 className="text-2xl font-bold text-white mb-6">
        Equipos: Crear un equipo tiene un costo de 500 coins, unirte a uno es gratis
      </h1>
      
      <div className="flex flex-col sm:flex-row gap-4 mb-6">
        <div className="relative flex-grow">
          <input
            className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pl-10 focus:outline-none focus:ring-2 focus:ring-blue-600"
            type="text"
            placeholder="Buscar Equipo"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
        <div className="relative">
          <select
            value={region}
            onChange={(e) => setRegion(e.target.value)}
            className="appearance-none bg-gray-800 text-white border border-gray-700 rounded-lg py-2 px-4 pr-8 focus:outline-none focus:ring-2 focus:ring-blue-600"
          >
            <option value="ALL">All Regions</option>
            <option value="LAN">LAN</option>
            <option value="LAS">LAS</option>
          </select>
          <Globe className="absolute right-3 top-2.5 text-gray-400 pointer-events-none" size={20} />
        </div>
      </div>

      {loading ? (
        <div className="flex justify-center items-center h-64">
          <Loader />
        </div>
      ) : (
        <div className="grid sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5 gap-6">
          {filteredTeams.map((team, index) => (
            <TeamCard
              key={index}
              name={team.name}
              image={team.image}
              open={team.accepting_requests}
              region={team.region}
              captain={team.captain_name}
              captain_id={team.captain_id}
              captain_avatar={team.captain_avatar}
              isUserLoggedIn={isUserLoggedIn}
              userProfile2={userProfile2}
              setShowModal={setShowModal}
            />
          ))}
        </div>
      )}

      {showModal && <Modal type={showModal} />}
    </div>
  );
};

export default TeamList;