import React, { useState, useEffect } from "react";
import axios from "axios";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import Transfers from "../../components/Transfers";
import { BiTransfer } from "react-icons/bi";
import Loader from "../../components/Loader";

const Index = () => {
  const [transfers, setTransfers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchTransfers = async () => {
      try {
        const response = await axios.get(
          "/api/player-status-logs/"
        );
        setTransfers(response.data['results']);
        setIsLoading(false);
      } catch (error) {
        console.error("There was an error fetching the data", error);
        setIsLoading(false); 
      }
    };

    fetchTransfers();
  }, []);
  return (
    <Container>
      <div className="flex flex-col gap-2 mt-4">
        <TitleContainer
          text={"Movimientos"}
          icon={<BiTransfer className="w-6 h-6 text-white" />}
          color="bg-blue-600"
          flex="start"
        />
        {isLoading ? <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50"><Loader /></div> : <Transfers transfers={transfers} />}
      </div>
    </Container>
  );
};

export default Index;
