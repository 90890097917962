import React, { useState, useEffect, useCallback, useMemo } from "react";
import Axios from "axios";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { Calendar, Clock, Users, MessageSquare, CheckSquare } from "lucide-react";
import ReactCountryFlag from "react-country-flag";
import Container from "../../components/Container";
import TitleContainer from "../../components/TitleContainer";
import Loader from "../../components/Loader";
import {
  top,
  jungler,
  mid,
  adc,
  support,
  coach,
  analyst,
} from "../../components/AssetsBarrel";

const CreatePage = ({ userData }) => {
  const [teamData, setTeamData] = useState(null);
  const [selectedPlayers, setSelectedPlayers] = useState([]);
  const [comment, setComment] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [csrfToken, setCsrfToken] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState({});
  const [currentTimes, setCurrentTimes] = useState({});
  const [selectedTimes, setSelectedTimes] = useState({});
  const [isWorking, setIsWorking] = useState(false);
  const [isDateTimeValid, setIsDateTimeValid] = useState(true);
  const [maxDate, setMaxDate] = useState("");
  const [minDate, setMinDate] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const updateTime = useCallback(() => {
    const now = new Date();
    setCurrentTimes({
      mexico: now.toLocaleTimeString("en-US", { timeZone: "America/Mexico_City" }),
      argentina: now.toLocaleTimeString("en-US", { timeZone: "America/Argentina/Buenos_Aires" }),
    });
  }, []);

  useEffect(() => {
    updateTime();
    const interval = setInterval(updateTime, 1000);
    return () => clearInterval(interval);
  }, [updateTime]);

  useEffect(() => {
    setIsLoading(true);
    if (userData?.team_info?.team_name) {
      Axios.get(`/api/team/${userData.team_info.team_name}`)
        .then((response) => {
          setTeamData(response.data);
          setIsLoading(false);
        })
        .catch((error) => {
          console.error("Error fetching team data:", error);
          setIsLoading(false);
        });
    }
  }, [userData]);

  useEffect(() => {
    Axios.get("/api/get-csrf-token/")
      .then((response) => setCsrfToken(response.data.csrfToken))
      .catch((error) => console.error(error));
  }, []);

  useEffect(() => {
    const currentTime = new Date();
    const twoWeeksLater = new Date(currentTime);
    twoWeeksLater.setDate(currentTime.getDate() + 14);
    setMaxDate(twoWeeksLater.toISOString().split("T")[0]);

    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    setMinDate(tomorrow.toISOString().split("T")[0]);
  }, []);

  const handlePlayerSelection = useCallback((playerId) => {
    setSelectedPlayers((prev) => {
      if (prev.includes(playerId)) {
        return prev.filter((id) => id !== playerId);
      } else if (prev.length < 7) {
        return [...prev, playerId];
      }
      return prev;
    });
  }, []);

  const handleDateTimeChange = useCallback((e) => {
    const { type, value } = e.target;
    if (type === "date") {
      setSelectedDate(value);
      setIsDateTimeValid(value >= minDate && value <= maxDate);
    } else if (type === "time") {
      setSelectedTime(value);
      setIsDateTimeValid(true);
    }
  }, [minDate, maxDate]);

  const calculateLocalTimes = useCallback((date, time, userTimeZone) => {
    const selectedDateTime = new Date(`${date}T${time}`);
    const options = { timeZone: userTimeZone };

    const formatDate = (dateTime) => {
      return dateTime.toLocaleString("en-US", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      });
    };

    return {
      mexico: formatDate(new Date(selectedDateTime.toLocaleString("en-US", { ...options, timeZone: "America/Mexico_City" }))),
      argentina: formatDate(new Date(selectedDateTime.toLocaleString("en-US", { ...options, timeZone: "America/Argentina/Buenos_Aires" }))),
    };
  }, []);

  useEffect(() => {
    if (selectedTime && selectedDate) {
      setSelectedTimes(calculateLocalTimes(selectedDate, selectedTime, userTimeZone));
    }
  }, [selectedTime, selectedDate, userTimeZone, calculateLocalTimes]);

  const handleScrimSubmission = async (e) => {
    e.preventDefault();
    setIsWorking(true);
    setIsLoading(true);

    if (selectedPlayers.length >= 2 && selectedPlayers.length <= 7) {
      try {
        const localDateTime = calculateLocalTimes(selectedDate, selectedTime, userTimeZone);
        const response = await Axios.post(
          `/team/create-scrim/`,
          {
            players: selectedPlayers,
            comments: comment,
            date: localDateTime.mexico,
            time: localDateTime.mexico.split(" ")[1],
          },
          {
            headers: { "X-CSRFToken": csrfToken },
          }
        );

        if (response.data.detail) {
          setModalContent({
            type: "success",
            message: response.data.detail,
            data: {
              players: selectedPlayers.map((id) => teamData.members.find((member) => member.id === id).discord_tag),
              comments: comment,
              date: localDateTime.mexico.split(" ")[0],
              time: localDateTime.mexico.split(" ")[1],
            },
          });
          setModalOpen(true);
        }
      } catch (error) {
        console.error("Error submitting the scrim:", error);
        setModalContent({
          type: "error",
          message: error.response?.data?.error || "Error submitting the scrim",
        });
        setModalOpen(true);
      }
    } else {
      setModalContent({
        type: "error",
        message: "Select between 2 to 7 players",
      });
      setModalOpen(true);
    }
    setIsWorking(false);
    setIsLoading(false);
  };

  const roleImages = useMemo(() => ({
    Top: top,
    Jungler: jungler,
    Mid: mid,
    ADC: adc,
    Support: support,
    Coach: coach,
    Analyst: analyst,
  }), []);

  const Modal = ({ isOpen, onClose, children }) => {
    if (!isOpen) return null;

    return (
      <motion.div
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50"
      >
        <motion.div
          initial={{ y: -50, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          exit={{ y: 50, opacity: 0 }}
          className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white max-w-md w-full"
        >
          {children}
          <button
            onClick={onClose}
            className="mt-4 w-full bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
          >
            Close
          </button>
        </motion.div>
      </motion.div>
    );
  };

  return (
    <Container>
      {isLoading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-70">
          <Loader />
        </div>
      )}
      <div className="flex flex-col gap-4 mt-4">
        <TitleContainer
          text="Agenda un scrim"
          icon={<Calendar className="w-6 h-6" />}
          color="bg-gradient-to-r from-yellow-400 to-yellow-600"
          flex="start"
        />
        {teamData && (
          <form
            onSubmit={handleScrimSubmission}
            className="bg-gray-800 p-6 rounded-lg shadow-lg text-white"
          >
            <div className="space-y-6">
              <div className="bg-gray-700 p-4 rounded-lg">
                <h2 className="font-bold text-xl mb-2 flex items-center">
                  <Clock className="w-5 h-5 mr-2" />
                  Horario Actual:
                </h2>
                <div className="flex justify-center gap-4 items-center">
                  <div className="flex items-center gap-2">
                    <ReactCountryFlag countryCode="MX" svg className="w-6 h-6" />
                    {currentTimes.mexico}
                  </div>
                  <div className="flex items-center gap-2">
                    <ReactCountryFlag countryCode="AR" svg className="w-6 h-6" />
                    {currentTimes.argentina}
                  </div>
                </div>
                <p className="text-yellow-500 font-bold mt-2 text-center">
                  Los scrims deben ser agendados en tu hora local
                </p>
              </div>

              <div>
                <h2 className="text-center text-2xl font-bold mb-4 flex items-center justify-center">
                  <Users className="w-6 h-6 mr-2" />
                  Selecciona tus jugadores para buscar un scrim
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                  {teamData.members
                    .filter((member) => teamData.players.includes(member.discord_tag))
                    .map((member) => (
                      <motion.label
                        key={member.id}
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`flex items-center p-3 bg-gray-700 rounded-lg cursor-pointer transition-all ${
                          selectedPlayers.includes(member.id)
                            ? "border-2 border-yellow-500"
                            : "border-2 border-transparent"
                        }`}
                      >
                        <input
                          type="checkbox"
                          className="sr-only"
                          onChange={() => handlePlayerSelection(member.id)}
                          checked={selectedPlayers.includes(member.id)}
                        />
                        <div className="flex items-center space-x-3">
                          <div className="relative">
                            <img
                              src={`https://cdn.discordapp.com/avatars/${member.user_id}/${member.avatar_id}.png`}
                              alt="Discord Avatar"
                              className="w-10 h-10 rounded-full bg-gray-600"
                              onError={(e) => {
                                e.target.onerror = null;
                                e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                              }}
                            />
                            <img
                              src={roleImages[member.role]}
                              alt={`${member.role} Icon`}
                              className="absolute -bottom-1 -right-1 w-5 h-5 rounded-full bg-gray-800 p-0.5"
                            />
                          </div>
                          <div>
                            <p className="font-medium">{member.discord_tag}</p>
                            <p className="text-sm text-gray-400">{member.soloq_account__summonerName}</p>
                          </div>
                        </div>
                      </motion.label>
                    ))}
                </div>
              </div>

              <div>
                <label htmlFor="comment" className="block text-sm font-medium text-gray-300 mb-2 flex items-center">
                  <MessageSquare className="w-5 h-5 mr-2" />
                  Comentario
                </label>
                <textarea
                  id="comment"
                  rows="4"
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  className="w-full p-3 bg-gray-700 border border-gray-600 rounded-md text-white resize-none focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition duration-300 ease-in-out"
                  placeholder="Agregar un comentario"
                ></textarea>
              </div>

              <div className="flex flex-wrap justify-center gap-6">
                <div>
                  <label htmlFor="date" className="block text-sm font-medium text-gray-300 mb-2 flex items-center">
                    <Calendar className="w-5 h-5 mr-2" />
                    Fecha:
                  </label>
                  <input
                    type="date"
                    id="date"
                    value={selectedDate}
                    onChange={handleDateTimeChange}
                    max={maxDate}
                    min={minDate}
                    className="p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition duration-300 ease-in-out"
                  />
                </div>
                <div>
                  <label htmlFor="time" className="block text-sm font-medium text-gray-300 mb-2 flex items-center">
                    <Clock className="w-5 h-5 mr-2" />
                    Hora:
                  </label>
                  <input
                    type="time"
                    id="time"
                    value={selectedTime}
                    onChange={handleDateTimeChange}
                    className="p-2 bg-gray-700 border border-gray-600 rounded-md text-white focus:ring-2 focus:ring-yellow-500 focus:border-transparent transition duration-300 ease-in-out"
                  />
                </div>
              </div>

              <div className="flex justify-center gap-4 text-center">
                <div>
                  <p className="font-bold flex items-center justify-center">
                    <ReactCountryFlag countryCode="MX" svg className="w-5 h-5 mr-2" />
                    Hora Seleccionada (Mexico):
                  </p>
                  <p>{selectedTimes.mexico || "Esperando horario..."}</p>
                </div>
                <div>
                  <p className="font-bold flex items-center justify-center">
                    <ReactCountryFlag countryCode="AR" svg className="w-5 h-5 mr-2" />
                    Hora Seleccionada (Argentina):
                  </p>
                  <p>{selectedTimes.argentina || "Esperando horario..."}</p>
                </div>
              </div>

              <div className="flex justify-center">
                <motion.button
                  type="submit"
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  className={`px-6 py-2 rounded-md text-white font-bold flex items-center ${
                    !isDateTimeValid ||
                    selectedPlayers.length < 2 ||
                    !selectedDate ||
                    !selectedTime ||
                    isWorking
                      ? "bg-gray-500 cursor-not-allowed"
                      : "bg-yellow-500 hover:bg-yellow-600"
                  } transition duration-300 ease-in-out`}
                  disabled={
                    !isDateTimeValid ||
                    selectedPlayers.length < 2 ||
                    !selectedDate ||
                    !selectedTime ||
                    isWorking
                  }
                >
                  <CheckSquare className="w-5 h-5 mr-2" />
                  Programar Scrim
                </motion.button>
              </div>
            </div>
          </form>
        )}
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setModalOpen(false)}>
        {modalContent.type === "success" ? (
          <>
            <h3 className="text-xl font-bold mb-4">{modalContent.message}</h3>
            <ul className="space-y-2">
              <li>
                <span className="font-bold">Jugadores:</span>{" "}
                <span className="text-sm">{modalContent.data.players.join(", ")}</span>
              </li>
              {modalContent.data.comments && (
                <li>
                  <span className="font-bold">Comentarios:</span>{" "}
                  <span className="text-sm">{modalContent.data.comments}</span>
                </li>
              )}
              <li>
                <span className="font-bold">Fecha:</span>{" "}
                <span className="text-sm">{modalContent.data.date}</span>
              </li>
              <li>
                <span className="font-bold">Hora:</span>{" "}
                <span className="text-sm">{modalContent.data.time}</span>
              </li>
            </ul>
            <motion.button
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => navigate("/league/scrims")}
              className="mt-4 w-full bg-yellow-500 hover:bg-yellow-600 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out"
            >
              Ver Scrims
            </motion.button>
          </>
        ) : (
          <p className="text-red-500">{modalContent.message}</p>
        )}
      </Modal>
    </Container>
  );
};

export default CreatePage;