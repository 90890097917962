import React, { useEffect, useState } from "react";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";
import { FaUser, FaBook, FaBan, FaTags, FaCheck, FaTimes } from "react-icons/fa";
import Loader from "../../components/Loader";

const ModalRules = ({ tournamentId, closeModal }) => {
  const [rules, setRules] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentVersion, setCurrentVersion] = useState("");
  const [bannedImages, setBannedImages] = useState({});

  const fetchRules = async () => {
    try {
      const versionResponse = await axios.get(
        "https://ddragon.leagueoflegends.com/api/versions.json"
      );
      const versions = versionResponse.data;
      const firstVersion = versions[0];
      setCurrentVersion(firstVersion);

      const rulesResponse = await axios.get(
        `api/tournament/${tournamentId}/rules`
      );
      setRules(rulesResponse.data);
    } catch (error) {
      console.error("Error al obtener reglas:", error);
      setRules([]);
    } finally {
      setLoading(false);
    }
  };

  const fetchBannedImages = async () => {
    const bannedImagesMap = {};

    for (const rule of rules) {
      const isChampion = rule.rule_text.toLowerCase().includes("campeón");
      const isSpell = rule.rule_text.toLowerCase().includes("hechizo");
      const isItem = rule.rule_text.toLowerCase().includes("ítem");

      if (isChampion || isSpell || isItem) {
        const imageUrl = await getBannedImageUrl(
          rule,
          isChampion,
          isSpell,
          isItem
        );
        bannedImagesMap[rule.id] = imageUrl;
      }
    }

    setBannedImages(bannedImagesMap);
  };

  const getBannedImageUrl = async (rule, isChampion, isSpell, isItem) => {
    const id = rule.banned;

    if (isChampion || isSpell) {
      return await getImageUrl(isChampion, id);
    } else if (isItem) {
      return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/item/${id}.png`;
    }
    return "";
  };

  const getImageUrl = async (isChampion, id) => {
    const dataType = isChampion ? "champion" : "summoner";
    const response = await axios.get(
      `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/data/en_US/${dataType}.json`
    );
    const data = response.data.data;

    if (isChampion) {
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          const champion = data[key];
          if (champion.id.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/${dataType}/${champion.image.full}`;
          }
        }
      }
    } else {
      for (const summonerSpell in data) {
        if (data.hasOwnProperty(summonerSpell)) {
          const spell = data[summonerSpell];
          if (spell.name.toLowerCase() === id.toLowerCase()) {
            return `https://ddragon.leagueoflegends.com/cdn/${currentVersion}/img/spell/${spell.image.full}`;
          }
        }
      }
    }

    return "";
  };

  useEffect(() => {
    fetchRules();
  }, [tournamentId]);

  useEffect(() => {
    if (currentVersion && rules.length > 0) {
      fetchBannedImages();
    }
  }, [currentVersion, rules]);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div className="bg-gray-800 p-6 rounded-lg shadow-xl border-2 border-yellow-500 text-white w-full max-w-4xl max-h-[90vh] overflow-hidden flex flex-col">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-2xl font-bold">Lista de Reglas</h3>
          <button
            onClick={closeModal}
            className="text-gray-400 hover:text-white transition-colors duration-200"
          >
            <AiOutlineClose size={24} />
          </button>
        </div>

        <div className="flex-grow overflow-auto">
          {loading ? (
            <div className="flex items-center justify-center h-64">
              <Loader />
            </div>
          ) : (
            <table className="w-full border-collapse">
              <thead className="bg-gray-700">
                <tr>
                  <th className="p-2 text-left"><FaUser className="inline mr-2" />Usuario</th>
                  <th className="p-2 text-left hidden md:table-cell"><FaBook className="inline mr-2" />Regla</th>
                  <th className="p-2 text-center"><FaBan className="inline mr-2" />Banned</th>
                  <th className="p-2 text-center hidden md:table-cell"><FaTags className="inline mr-2" />Tier</th>
                  <th className="p-2 text-center"><FaCheck className="inline mr-2" />Activo</th>
                </tr>
              </thead>
              <tbody>
                {rules.map((rule, index) => (
                  <tr key={rule.id} className={index % 2 === 0 ? "bg-gray-700 bg-opacity-50" : ""}>
                    <td className="p-2 flex items-center space-x-2">
                      <img
                        src={`https://cdn.discordapp.com/avatars/${rule.user_id}/${rule.user_avatar}.png`}
                        alt="Discord Avatar"
                        className="w-8 h-8 rounded-full bg-gray-600"
                        onError={(e) => {
                          e.target.onerror = null;
                          e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                        }}
                      />
                      <span>{rule.user_discord_tag}</span>
                    </td>
                    <td className="p-2 hidden md:table-cell">{rule.rule_text}</td>
                    <td className="p-2 text-center">
                      {bannedImages[rule.id] ? (
                        <img
                          src={bannedImages[rule.id]}
                          alt="Banned"
                          className="w-8 h-8 rounded-full bg-gray-600 inline-block"
                          onError={(e) => {
                            e.target.onerror = null;
                            e.target.src = "https://i.redd.it/j3t4cvgywd051.png";
                          }}
                        />
                      ) : (
                        <span>No Image</span>
                      )}
                    </td>
                    <td className="p-2 text-center hidden md:table-cell">{rule.tier}</td>
                    <td className="p-2 text-center">
                      {rule.is_active ? (
                        <FaCheck className="inline text-green-500" />
                      ) : (
                        <FaTimes className="inline text-red-500" />
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </div>

        <button
          onClick={closeModal}
          className="mt-4 bg-yellow-500 text-white font-bold py-2 px-4 rounded hover:bg-yellow-600 transition-colors duration-200"
        >
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default ModalRules;